<template>
  <div class="home-container">
    <Header :userInfo="userInfo"></Header>
    <router-view></router-view>
  </div>
</template>
<script>
import { reactive, toRefs, onBeforeMount, onMounted, watch } from "vue";
import Header from "@/components/home/Header.vue";
import { requestUser } from "@/utils/api";
import { homePage, quit } from "@/utils/tools";
// import 'amfe-flexible'; // pc适配
export default {
  name: "Home",
  components: {
    Header,
  },
  setup() {
    const data = reactive({
      // 模拟用户信息
      userInfo: {}
    });
    // 拉取用户信息
    const initUserInfo = async () => {
      try {
        const result = await requestUser();
        console.log("personal info success:", result);
      } catch (error) {
        console.log("personal info error:");
        homePage();
      }
    };
    onBeforeMount(() => {
      initUserInfo();
    });
    onMounted(() => {});
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style scoped>

.home-container {
  width: 100%;
  /* height: 100vh; */
  height: auto;
  padding-top: 74px;
  background: #f8f8f8;
}
</style>
