<!--  -->
<template>
  <div class="search-wrap" :class="[isChoose ? 'active' : '']">
    <div class="search-icon">
      <img src="../../assets/search.png" alt="" />
    </div>
    <input
      type="text"
      placeholder="搜索"
      class="search-input"
      @focus="handleFocus"
      @blur="handleBlur"
      v-model="inputVal"
      @keyup.enter="submitSearchText"
    />
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  defineComponent,
  onMounted,
} from "vue";
import router from "@/router/index.js";
export default defineComponent({
  name: "HeaderSearch",
  setup(props, { emit }) {
    const data = reactive({
      inputVal:'',
      isChoose: false,
      handleFocus: () => {
        data.isChoose = true;
      },
      handleBlur: () => {
        data.isChoose = false;
      },
      submitSearchText: () => {
        emit("handleSubmit",data.inputVal)
      },
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
});
</script>
<style scoped lang="scss">
.search-wrap {
  width: 113px;
  height: 40px;
  background: #f6f7fa;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: width 0.7s;
   @media only screen and (min-width: 1200px) and (max-width: 1400px) {
     width: 133px;
     height: 50px;
     border-radius: 25px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1199px) {
    width: 133px;
     height: 50px;
     border-radius: 25px;
  }
  @media only screen and (min-width: 640px) and (max-width: 959px) {
    width: 133px;
     height: 50px;
     border-radius: 25px;
  }
  &.active {
    width: 200px;
    .search-icon {
      width: 20%;
    }
    .search-input {
      flex: 1;
    }
  }
  .search-icon {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    transition: width 0.5s;
    img {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  .search-input {
    display: block;
    width: 50%;
    height: 100%;
    border: 0;
    outline: none;
    outline-style: none;
    background: #f6f7fa;
    border-radius: 50%;
    color: #505050;
    font-size: 14px;
    // font-family: "SourceRegular";
    font-family: "PingFang";
    transition: width 0.8s;
  }
}
</style>
