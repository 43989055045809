<!--  -->
<template>
  <div class="navbar-wrap">
    <ul>
      <li v-for="item of list" :key="item.id">
        <router-link class="routerLink" :to="item.path">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted, computed } from "vue";
import router from "@/router/index.js";
export default {
  name: "HeaderNavbar",
  props: {
    navList: Array,
  },
  setup(props, { emit }) {
    const data = reactive({
      list: computed(() => {
        return props.navList;
      }),
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../styles/mixin.scss";
.navbar-wrap {
  position: absolute;
  left: 320px;
  width: 300px;
  height: 100%;
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    width: 320px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1199px) {
    width: 380px;
  }
  @media only screen and (min-width: 640px) and (max-width: 959px) {
    width: 500px;
  }
  ul {
    @include flex(row, space-between, center);
    width: 100%;
    height: 100%;
    li {
      display: inline-block;
      width: 94px;
      height: 32px;
      background: #fff;
      color: #333;
      // @media only screen and (min-width: 1001px) and (max-width: 1400px) {
      //   width: 100px;
      //   height: 40px;
      // }
      // @media only screen and (min-width: 540px) and (max-width: 1000px) {
      //   width: 150px;
      //   height: 42px;
      // }

      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 100px;
        height: 40px;
      }
      @media only screen and (min-width: 960px) and (max-width: 1199px) {
        width: 150px;
        height: 42px;
      }
      @media only screen and (min-width: 640px) and (max-width: 959px) {
        width: 150px;
        height: 48px;
      }
      .routerLink {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: #fff;
        color: #333;
        border-radius: 16px;
        text-align: center;
        line-height: 32px;
        text-decoration: none;
        // font-family: "SourceRegular";
        font-family: "PingFang";
        font-size: 14px;
        font-weight: 900;
        // @media only screen and (min-width: 1001px) and (max-width: 1400px) {
        //   border-radius: 20px;
        //   line-height: 40px;
        // }
        // @media only screen and (min-width: 540px) and (max-width: 1000px) {
        //   border-radius: 21px;
        //   line-height: 42px;
        // }

        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
          border-radius: 20px;
          line-height: 40px;
        }
        @media only screen and (min-width: 960px) and (max-width: 1199px) {
          border-radius: 21px;
          line-height: 42px;
        }
        @media only screen and (min-width: 640px) and (max-width: 959px) {
          border-radius: 21px;
          line-height: 48px;
        }
        &.active {
          background: #3d8fff;
          color: #ffffff;
        }
        &:hover {
          cursor: pointer;
          .routerLink {
            background: #3d8fff;
            color: #ffffff;
            transition: all linear;
          }
        }
      }
    }
  }
}
</style>
