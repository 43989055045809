<!-- avatar -->
<template>
  <el-dropdown @command="handleCommand">
    <div class="el-dropdown-link">
      <el-avatar icon="el-icon-user-solid" :src="userAvatar"></el-avatar>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <!-- <el-dropdown-item command="personl">个人中心</el-dropdown-item> -->
        <el-dropdown-item command="admin" v-if="isHome && showAdmin"
            >管理后台</el-dropdown-item
          >
        <el-dropdown-item command="loginout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
  computed
} from "vue";
import { ElMessage } from "element-plus";
import { loginQuit } from "@/utils/api";
import { homePage, quit, getUserInfo, checkUserRole } from "@/utils/tools";
import { role } from "@/utils/constant";
import router from "@/router/index.js";
// const userAvatar = require("../../assets/userAvatar.png");
export default defineComponent({
  name: "HeaderAvatar",
  props: {
    avatar: String,
  },
  setup(props) {
    // 是否是管理后台页面
    let userInfo = reactive(getUserInfo());
    console.log(JSON.parse(JSON.stringify(userInfo)))
    // 是否是（前台）首页
    let isHome = computed(() => {
      let chref = router.currentRoute.value.path;
      console.log(router)
      return (
        chref &&
        // (chref.startsWith("/pages/info") || chref.startsWith("pages/info"))
        (chref.startsWith("/home") || chref.startsWith("home"))

      );
    });
    // 是否需要显示管理后台
    let showAdmin = computed(() => {
      return checkUserRole(role.owner) || checkUserRole(role.abover);
    });

  let toAdmin = () =>{
    router.push("/admin")
  }
    const data = reactive({
      handleCommand: (command) => {
        switch (command) {
          case "loginout":
            quit();
            break;
          case "admin":
            toAdmin()
            break;
          default:
            ElMessage.success({
              message: `click on item ${command}`,
              type: "success",
            });
            break;
        }
      },
      userAvatar: "",
    });
    onBeforeMount(() => {});
    onMounted(() => {
      data.userAvatar = props.avatar;
    });
    const refData = toRefs(data);
    return {
      ...refData,
      isHome,
      showAdmin,
      userInfo,
    };
  },
});
</script>
<style scoped lang="scss">
::v-deep(.el-avatar) img {
    vertical-align: middle;
    width: 100%;
  }
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  display: flex;
  align-content: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
