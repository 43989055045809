<!-- tips -->
<template>
  <div class="tips-wrap">
    <el-dropdown @command="handleCommand">
      <div class="el-dropdown-link">
        <div class="img-wrap">
          <span class="num" v-if="msgList.length > 0">{{ num }}</span>
          <img src="../../assets/tips.png" alt="" />
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu v-show="msgList.length>0">
          <el-dropdown-item
            v-for="item in msgList"
            :key="item.id"
            :command="item.msg"
          >
            {{ item.msg }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted, computed } from "vue";
export default {
  name: "HeaderTips",
  props: {
    msgList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const data = reactive({
      num: computed(() => {
        return props.msgList.length;
      }),
      handleCommand: (command) => {
        console.log(command);
      },
    });

    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  //   color: #409eff;
  width: 20px;
  height: 20px;

  .img-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    .num {
      position: absolute;
      display: block;
      width: 17px;
      height: 13px;
      top: -6px;
      right: -6px;
      border-radius: 7px;
      background: #ff2929;
      font-size: 12px;
      line-height: 13px;
      text-align: center;
      color: #fff;
      font-family: "SourceRegular";
      font-weight: 400;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
