<!-- 头部Header -->
<template>
  <div class="home-header">
    <div class="content">
      <!-- logo -->
      <div class="logo">
        <img src="../../assets/beizengLogo.jpg" alt="" />
      </div>
      <!-- 导航 -->
      <div class="navbar">
        <HeaderNavbar :nav-list="navList"></HeaderNavbar>
      </div>
      <!-- 工具栏 -->
      <div class="toolbar">
        <!-- 搜索框 -->
        <div class="toolbar-search">
          <HeaderSearch @handle-submit="submitInputVal"></HeaderSearch>
        </div>
        <!-- 通知 -->
        <div class="toolbar-tips">
          <HeaderTips :msg-list="tips"></HeaderTips>
        </div>
        <!-- 用户 -->
        <div class="toolbar-user">
          <HeaderAvatar :avatar="avatar"></HeaderAvatar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  defineComponent,
  onMounted,
  watch,
  computed,
} from "vue";
import HeaderNavbar from "@/components/home/HeaderNavbar.vue";
import HeaderSearch from "@/components/home/HeaderSearch.vue";
import HeaderAvatar from "@/components/home/HeaderAvatar.vue";
import HeaderTips from "@/components/home/HeaderTips.vue";
import { ElMessage } from "element-plus";
// import { onBeforeRouteUpdate, useRoute } from "vue-router";
export default defineComponent({
  name: "Header",
  components: {
    HeaderNavbar,
    HeaderSearch,
    HeaderTips,
    HeaderAvatar,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    // const route = useRoute();
    // watch(
    //   () => route.path,
    //   () => {
    //     console.log("监听到变化");
    //   }
    // );
    // onBeforeRouteUpdate((to) => {
    //   console.log(to, "=====");
    // });
    const data = reactive({
      // 导航
      navList: [
        { id: 1, name: "首页", path: "/home/main" },
        { id: 2, name: "应用中心", path: "/home/application" },
        { id: 3, name: "制度中心", path: "/home/system" },
      ],
      // 消息通知
      tips: [
        // { id: 1, msg: "模拟消息11111" },
        // { id: 2, msg: "模拟消息22222" },
        // { id: 3, msg: "模拟消息33333" },
        // { id: 4, msg: "模拟消息44444" },
      ],
      // 头像
      avatar: computed(() => {
        return props.userInfo.avatar;
      }),
      submitInputVal: (inputVal) => {
        console.log(inputVal);
        ElMessage.success(inputVal);
      },
    });
    onBeforeMount(() => {});
    onMounted(() => {
      console.log("props", props.userInfo);
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
});
</script>
<style scoped lang="scss">
@import "../../styles/mixin.scss";
.home-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  height: 74px;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(214, 222, 225, 0.68);
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    height: 84px;
  }
  @media only screen and (min-width: 960px) and (max-width: 1199px) {
    height: 94px;
  }
  @media only screen and (min-width: 640px) and (max-width: 959px) {
    height: 114px;
  }
  .content {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
    // @include flex(row, space-between, center);
    @media only screen and (min-width: 540px) and (max-width: 959px) {
      width: 90%;
    }

    .logo {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -18px;
      width: 160px;
      height: 37px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .toolbar {
      position: absolute;
      right: 0;
      height: 100%;
      width: 40%;
      @include flex(row, flex-end, center);
      // &-search{
      //   flex: 1;
      // }
      &-tips {
        margin: 0 45px 0 35px;
      }
    }
  }
}
</style>
